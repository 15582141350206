var tabs = {

	init: function () {
		$('.m-tabs.js-tabs-internal:not(.js-tabs-done)').each(function () {
			var wrapper = $(this);
			var tabLinks = wrapper.find('.js-tabs-link');
			var tabGroup = wrapper.find('.js-tab');

			// show first
			tabLinks.first().addClass('active');
			tabGroup.hide().first().show();

			// tab click
			tabLinks.off('click').on('click', function () {
				var link = $(this);
				var id = link.attr('href');
				// links
				tabLinks.removeClass('active');
				link.addClass('active');
				// tabs
				tabGroup.hide().filter(id).show();
				// scroll
				if (Modernizr.mq('(max-width:' + (site.bp.tablet - 1) + 'px)')) {
					site.scrollTo(tabGroup.filter(id).offset().top - 100);
				}
				return false;
			});

			// done
			wrapper.addClass('js-tabs-done');
		});
	}

};

$(function () {
	tabs.init();
	$(document).ajaxComplete(function () {
		tabs.init();
	});
});