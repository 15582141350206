var masthead = {

	min: 300,

	init: function () {
		$('.m-masthead:not(.js-masthead-done)').each(function () {
			var wrapper = $(this);
			var e = wrapper.find('.js-resize');
			var t = null;
			var mute = wrapper.find('.js-mute');
			var isMute = true;

			// add video
			if (wrapper.attr('data-video-mp4') != '' && wrapper.attr('data-video-ogv') != '') {
				// check screen size
				if (Modernizr.mq('(min-width:' + site.bp.tabletLarge + 'px)') && Modernizr.video) {
					var tmp = Hogan.compile($('#mastheadVideoTemplate').html());
					var render = tmp.render({
						MP4: wrapper.attr('data-video-mp4'),
						OGV: wrapper.attr('data-video-ogv')
					});
					wrapper.find('.js-image').after(render);
					mute.show().off('click').on('click', function () {
						mute.toggleClass('active');
						wrapper.find('video').prop('muted', !isMute);
						isMute = !isMute;
					});
				}
			}

			// load
			e.height(masthead.getHeight());

			// resize
			site.win.on('resize', function () {
				clearTimeout(t);
				t = setTimeout(function () {
					if (site.win.scrollTop() <= masthead.min) {
						e.height(masthead.getHeight());
					}
				}, 200);
			});

			// done
			wrapper.addClass('js-masthead-done');
		});
	},

	getHeight: function () {
		var h = parseInt(site.win.height());
		return h < masthead.min ? masthead.min : h;
	}

};

$(function () {
	masthead.init();
});