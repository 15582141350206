var propertyList = {

	init: function () {
		$('.m-property-list:not(.js-property-list-done)').each(function () {
			var wrapper = $(this);
			var list = wrapper.find('.js-accordion');
			var accordion = null;
			var done = false;
			var limit = site.bp.desktop;

			if (list.find('.js-accordion-item').length > 2) {

				function check() {
					if (Modernizr.mq('(min-width:' + limit + 'px)') && !done) {
						accordion = list.kwicks({
							behavior: 'menu',
							spacing: 0,
							maxSize: 600,
							duration: 400
						});
						done = true;
					}
					else if (Modernizr.mq('(max-width:' + (limit - 1) + 'px)') && done) {
						accordion.kwicks('destroy');
						done = false;
					}
				}

				// load
				check();

				// resize
				site.win.resize(function () {
					check();
				});

				// link click
				list.find('.js-accordion-link').off('click').on('click', function () {
					var link = $(this);
					if (Modernizr.touchevents && done) {
						if (accordion !== null) {
							var index = parseInt(link.attr('data-index'), 10) - 1;
							if (accordion.kwicks('selected') == index) {
								return true;
							}
							accordion.kwicks('select', index);
						}
						return false;
					}
				});

			}

			// done
			wrapper.addClass('js-property-list-done');
		});
	}

};

$(function () {
	propertyList.init();
	$(document).ajaxComplete(function () {
		propertyList.init();
	});
});