var site = {

	win: null,

	bp: {
		mobileLarge: 500,
		tablet: 620,
		tabletLarge: 788,
		desktop: 980,
		desktopLarge: 1100,
		large: 1220
	},

	preInit: function () {
		// window object
		site.win = $(window);
		// nav
		site.nav();
		// init
		site.init();
	},

	init: function () {
		site.smoothScroll();
		site.darken();
		site.cms();
	},

	nav: function () {
		var body = $('body');
		var title = $('#nav-menu-toggle');
		var menu = $('#nav-menu');
		var page = $('#nav-page');
		var fade = $('#nav-menu-fade');
		var allow = true;
		var open = false;

		function _toggle() {
			// loading
			title.addClass('loading');
			if (open) {
				// close
				menu.animate({
					'left': menu.width() * -1
				}, 250, function () {
					// hide fade
					fade.fadeOut(250);
					// classes
					title.removeClass('loading active');
					// enable scroll
					body.add(page).removeClass('slideout-scroll');
					// set open
					open = false;
					// allow
					allow = true;
				})
				// set open
				open = false;
			}
			else {
				// show fade
				fade.fadeIn(250);
				// disable scroll
				body.add(page).addClass('slideout-scroll');
				// open
				menu.animate({
					'left': 0
				}, 250, function () {
					// classes
					title.removeClass('loading').addClass('active');
					// set open
					open = true;
					// allow
					allow = true;
				});				
			}			
		}

		// title click
		title.off('click').on('click', function () {
			if (!allow) return false;
			allow = false;
			_toggle();
			return false;
		});

		// anywhere click
		body.on('click', function (e) {
			if ($(e.target).closest('#nav-menu').length === 0 && open) {
				_toggle();
			}
		});
	},

	darken: function () {
		$('.js-darken:not(.js-darken-done)').each(function () {
			var wrapper = $(this);
			var content = $('.js-darken-start:first');

			function check() {
				if ((site.win.scrollTop() + (wrapper.height() * 2)) < content.offset().top) {
					wrapper.removeClass('dark');
				}
				else {
					wrapper.addClass('dark');
				}
			}

			// load
			check();

			// scroll
			site.win.on('scroll', function () {
				check();
			});

			// resize
			site.win.on('resize', function () {
				check();
			});

			// done
			wrapper.addClass('js-darken-done');
		});
	},

	cms: function() {
		$('.cms:not(.js-cms-done)').each(function () {
			var wrapper = $(this);

			// tables
			wrapper.find('table:not(.js-cms-table-done)').wrap('<div class="cms-table" />')

			// done
			wrapper.addClass('js-cms-done');
		});
	},

	smoothScroll: function () {
		$('a[href*="#"]:not(.scrollDone)').each(function () {
			var link = $(this);
			link.on('click', function () {
				if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
					var target = $(this.hash);
					target = target.length && target || $('[name=' + this.hash.slice(1) + ']');
					if (target.length) {
						var targetOffset = target.offset().top;
						if (site.win.scrollTop != targetOffset) {
							site.scrollTo(targetOffset);
						}
						return false;
					}
				}
			});
			// done
			link.addClass('scrollDone');
		});
	},

	scrollTo: function (offset, speed) {
		var value = parseInt(offset, 10);
		if (value < 0 && $('html, body').scrollTop !== value) return;
		$('html, body').animate({ scrollTop: value }, speed || 500);
	},

	analytics: {

		init: function() {
			$('a[data-analytics-event]:not(.js-analytics-done)').each(function () {
				var link = $(this);
				var e = link.attr('data-track-event').split('|');

				// click
				link.on('click', function () {
					site.analytics.event(e[0], e[1], e[2])
				});

				// done
				link.addClass('js-analytics-done');
			});
		},

		event: function (category, action, label) {
			if (typeof ga !== 'undefined') {
				ga('send', 'event', category, action, label);
			}
		},

		page: function (url) {
			if (typeof ga !== 'undefined') {
				ga('send', 'pageview', url);
			}
		}

	}

};

$(function () {
	site.preInit();
	$(document).ajaxComplete(function () {
		site.init();
	});
});