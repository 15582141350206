var sideNav = {

	init: function() {
		$('.m-side-nav:not(.js-nav-done)').each(function () {
			var wrapper = $(this);
			var title = wrapper.find('.js-title');
			var menu = wrapper.find('.js-nav');
			var allow = true;

			// click functionality
			title.off('click').on('click', function () {
				if (allow) {
					allow = false;
					title.toggleClass('active');
					menu.slideToggle(function() {
						allow = true;
					});
				}
				return false;
			});

			// done
			wrapper.addClass('js-nav-done');
		});
	}

	

};

$(function () {
	sideNav.init();
	$(document).ajaxComplete(function () {
		sideNav.init();
	});
});