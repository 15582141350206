var explore = {

	speed: 500,

	init: function () {
		$('.m-explore:not(.js-explore-done)').each(function () {
			var wrapper = $(this);
			var allow = true;
			var open = null;
			var links = wrapper.find('.js-gallery-link');
			var replace = wrapper.find('.js-replace');
			var loader = replace.find('.js-gallery-loader');
			var data = $.parseJSON(wrapper.find('script[type="application/json"]').first().html());
			var tmpTab = $('#exploreTabTemplate').html();
			tmpTab = Hogan.compile(tmpTab);
			var tmpGallery = $('#exploreGalleryTemplate').html();
			tmpGallery = Hogan.compile(tmpGallery);

			// add galleries
			for (var i = 0; i < data.length; i++) {
				replace.append(tmpTab.render(data[i]));
			}

			function _showTab(id, link) {
				if (open != null) {
					loader.fadeIn(parseInt(explore.speed / 2, 10), function () {
						open = null;
						_showTab(id, link);
					});
					return;
				}
				var tab = tabs.filter(id);
				// hide tabs, show tab
				tabs.hide().filter(id).show();
				// show loader
				loader.show();
				// replace gallery
				var galleryId = parseInt(link.attr('data-gallery'), 10) - 1;
				tab.find('.js-gallery-replace').html(tmpGallery.render(data[galleryId]));
				// show tab
				replace.slideDown(explore.speed, function () {
					// scroll to tab
					if (Modernizr.mq('(min-width:' + site.bp.tablet + 'px)')) {
						site.scrollTo(link.offset().top);
					}
					else {
						site.scrollTo(replace.offset().top);
					}
					// init slider
					setTimeout(function () {
						tab.find('.js-gallery').bxSlider({
							mode: 'fade',
							pager: false,
							onSliderLoad: function () {
								loader.fadeOut(parseInt(explore.speed / 2, 10), function () {
									// set open
									open = id;
									// allow
									allow = true;
								});
							}
						}).addClass('js-gallery-done');
					}, 200);
				});
			}

			function _hideTab() {
				replace.slideUp(explore.speed, function () {
					// remove active
					links.removeClass('active');
					// unset open
					open = null;
					// allow
					allow = true;
				});
			}

			var tabs = wrapper.find('.js-gallery-tab');

			// click functionality
			links.off('click').on('click', function () {
				var link = $(this);
				var id = link.attr('data-show-gallery');

				if (id == null || id == "" || !allow) return false;
				allow = false;

				// actions
				if (link.is('.active')) {
					// hide tab
					_hideTab();
				}
				else {
					// remove active
					links.removeClass('active');
					// add active
					link.addClass('active');
					// show tab
					_showTab(id, link);
				}				

				return false;
			});

			// done
			wrapper.addClass('js-explore-done');
		});
	}

};

$(function () {
	explore.init();
	$(document).ajaxComplete(function () {
		explore.init();
	});
});