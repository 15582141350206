var faqs = {

	init: function () {
		$('.m-faqs:not(.js-faqs-done)').each(function () {
			var wrapper = $(this);
			var questions = wrapper.find('.js-question');
			var allow = true;

			// click functionality
			questions.off('click').on('click', function () {
				var q = $(this);
				var a = q.next('.js-answer').first();
				if (!allow) return false;
				allow = false;
				q.toggleClass('active');
				a.slideToggle(250, function () {
					allow = true;
				});
				return false;
			});

			// done
			wrapper.addClass('js-faqs-done');
		});
	}

};

$(function () {
	faqs.init();
	$(document).ajaxComplete(function () {
		faqs.init();
	});
});