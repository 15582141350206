var gallery = {

	speed: 250,

	init: function () {
		$('.m-gallery:not(.js-gallery-done)').each(function () {
			var wrapper = $(this);
			var body = $('body');
			var allow = true;
			var open = false;
			var scrollTo = 0;
			var title = wrapper.prev('.m-gallery__title').first().find('.js-title');
			var replace = wrapper.find('.js-replace');
			var loader = wrapper.find('.js-gallery-loader');
			var data = $.parseJSON(wrapper.find('script[type="application/json"]').first().html());
			var tmp = $('#galleryTemplate').html();
			tmp = Hogan.compile(tmp);

			function _showGallery() {
				if (open) {
					// enable scroll
					body.removeClass('disable-scroll');
					// scroll to
					site.scrollTo(scrollTo, 1);
					// hide gallery
					wrapper.fadeOut(gallery.speed, function () {
						// unset open
						open = false;
						// allow
						allow = true;
					});
					return;
				}
				// add galleries
				replace.html(tmp.render({
					ImageUrls: data
				}));
				// show gallery
				wrapper.fadeIn(gallery.speed, function () {
					// set scroll to
					scrollTo = site.win.scrollTop();
					// disable scroll
					body.addClass('disable-scroll');
					// init slider
					setTimeout(function () {
						wrapper.find('.js-gallery').bxSlider({
							mode: 'fade',
							pager: false,
							onSliderLoad: function () {
								loader.fadeOut(parseInt(gallery.speed / 2, 10), function () {
									// set open
									open = true;
									// allow
									allow = true;
								});
							}
						}).addClass('js-gallery-done');
					}, 200);
				});
			}

			// click functionality
			title.off('click').on('click', function () {				
				if (!allow) return false;
				allow = false;
				// toggle class
				title.toggleClass('active');
				// toggle gallery
				_showGallery();
				return false;
			});

			// resize
			site.win.on('resize', function () {
				if (open) {
					// toggle class
					title.removeClass('active');
					// toggle gallery
					_showGallery();
				}
			});

			// done
			wrapper.addClass('js-explore-done');
		});
	},

	show: function (title, gallery) {

	}

};

$(function () {
	gallery.init();
	$(document).ajaxComplete(function () {
		gallery.init();
	});
});