var forms = {

	init: function () {
		forms.maxLength();
		forms.checkAll();
		forms.showIf.init();
		forms.datepicker.init();
	},

	maxLength: function() {
		$('input[data-maxlength]:not(.js-max-done), textarea[data-maxlength]:not(.js-max-done)').each(function () {
			var input = $(this);
			var count = $(input.attr('data-maxlength-info'));
			// get limit
			var limit = parseInt(input.attr('data-maxlength'), 10);
			// limit on keyup, click, paste or blur
			input.on('keyup click paste blur', function () {
				var remaining = limit - input.val().length;
				if (remaining <= 0) {
					input.val(input.val().substr(0, limit));
					remaining = 0;
				}
				count.text(remaining);
			}).blur();
			// done
			input.addClass('js-max-done');
		});
	},

	checkAll: function () {

		$('.form-checks input[data-check-all-toggle]:not(.js-check-all-toggle-done)').each(function () {
			var input = $(this);
			var group = $('input[data-check-all="' + input.attr('data-check-all-toggle') + '"]');
			var reverse = input.attr('data-reverse') === 'true' ? true : false;

			// load
			if (group.length == group.filter(':checked').length) {
				input.prop('checked', true);
			}
			if (input.is(':checked')) {
				group.prop('checked', true);
			}

			// toggle
			input.on('change', function () {
				if (!input.is(':checked') && reverse) {
					// check
					group.prop('checked', true);
				}
				else if (input.is(':checked') && reverse) {
					// uncheck
					group.prop('checked', false);
				}
				else if (input.is(':checked')) {
					// check
					group.prop('checked', true);
				}
				else {
					// uncheck
					group.prop('checked', false);
				}
			});

			// group change
			group.on('change', function () {
				var e = $(this);
				if (!e.is(':checked') && !reverse) {
					// uncheck
					input.prop('checked', false);
				}
				else if (e.is(':checked') && reverse) {
					// uncheck
					input.prop('checked', false);
				}
			});

			// done
			input.addClass('js-check-all-toggle-done');
		});

	},

	datepicker: {

		dir: '/js/plugins/jquery-ui/datepicker-{0}.js',

		preInit: function () {
			var js = null;
			// detect language
			var lang = $('html').attr('lang');
			// generate js url
			switch (lang) {
				case 'de':
					js = forms.datepicker.dir.replace('{0}', 'de');
					break;
				case 'es':
					js = forms.datepicker.dir.replace('{0}', 'es');
					break;
				case 'fr':
					js = forms.datepicker.dir.replace('{0}', 'fr');
					break;
				default:
					js = null;
			};
			// load js
			if (js !== null) {
				!function (d, s, id, file) {
					var js, fjs = d.getElementsByTagName(s)[0];
					if (!d.getElementById(id)) {
						js = d.createElement(s);
						js.id = id;
						js.src = file;
						fjs.parentNode.insertBefore(js, fjs);
					}
				}(document, 'script', 'jquery-ui-locale-js', js);
			}			
			// continue
			forms.datepicker.init();
		},

		init: function() {
			$('input.js-datepicker:not(.js-datepicker-done)').each(function () {
				var input = $(this);
				var min = $(input.attr('data-min-date'));
				var max = $(input.attr('data-max-date'));

				// init
				var dp = input.datepicker({
					dateFormat: "dd/mm/y",
					firstDay: 1,
					minDate: '+1d',
					maxDate: '+1y',
					onClose: function (selectedDate) {
						if (max.length) {
							max.datepicker('option', 'minDate', selectedDate);
						}
					}
				});

				// done
				input.addClass('js-datepicker-done');
			});
		}
		
	},

	showIf: {

		init: function () {
			forms.showIf.checks();
			forms.showIf.radios();
			forms.showIf.dropdowns();
		},

		disableFormElements: function (parent) {
			parent.find('input, select').attr('disabled', 'disabled');
		},

		enableFormElements: function (parent) {
			parent.find('input, select').removeAttr('disabled');
		},

		checks: function () {
			$('.form-checks input[data-show-if]:checkbox:not(.js-show-if-done)').each(function () {
				var input = $(this);
				var reverse = input.attr('data-reverse') === 'true' ? true : false;
				var ref = input.attr('data-show-if').split(',');

				var check = function () {
					if (!input.is(':checked') && reverse) {
						// show
						for (var i = 0; i < ref.length; i++) {
							$(ref[i]).show();
							forms.showIf.enableFormElements($(ref[i]));
						}
					}
					else if (input.is(':checked') && reverse) {
						// hide
						for (var i = 0; i < ref.length; i++) {
							$(ref[i]).hide();
							forms.showIf.disableFormElements($(ref[i]));
						}
					}
					else if (input.is(':checked')) {
						// show
						for (var i = 0; i < ref.length; i++) {
							$(ref[i]).show();
							forms.showIf.enableFormElements($(ref[i]));
						}
					}
					else {
						// hide
						for (var i = 0; i < ref.length; i++) {
							$(ref[i]).hide();
							forms.showIf.disableFormElements($(ref[i]));
						}
					}
				};

				// change functionality
				input.on('change', function () {
					check();
				});

				// on load
				check();

				// done
				input.addClass('js-show-if-done');
			});
		},

		radios: function () {
			$('.form-checks:not(.js-show-if-done) input[data-show-if]:radio').closest('.form-checks').each(function () {
				var wrapper = $(this);

				// change functionality
				wrapper.find('input').each(function () {
					var input = $(this);
					var group = input.attr('name');

					var check = function (opt, show) {
						var ref = opt.attr('data-show-if').split(',');
						if (show) {
							for (var i = 0; i < ref.length; i++) {
								$(ref[i]).show();
								forms.showIf.enableFormElements($(ref[i]));
							}
						}
						else {
							for (var i = 0; i < ref.length; i++) {
								$(ref[i]).hide();
								forms.showIf.disableFormElements($(ref[i]));
							}
						}
					};

					// on change
					input.on('change', function () {
						// hide non-selected
						$('input[name="' + group + '"][data-show-if]:not(:checked)').each(function () {
							var opt = $(this);
							// hide
							check(opt, false);
						});
						// show selected
						$('input[name="' + group + '"][data-show-if]:checked').each(function () {
							var opt = $(this);
							// show
							check(opt, true);
						});
					});

					// on load
					if (input.is('[data-show-if]:not(:checked)')) {
						var opt = $(this);
						// hide
						check(opt, false);
					}
					if (input.is('[data-show-if]:checked')) {
						var opt = $(this);
						// show
						check(opt, true);
					}

				});

				// done
				wrapper.addClass('js-show-if-done');
			});
		},

		dropdowns: function () {
			$('select:not(.js-show-if-done) option[data-show-if]').closest('select').each(function () {
				var select = $(this);

				var check = function (opt, show) {
					var ref = opt.attr('data-show-if').split(',');
					if (show) {
						for (var i = 0; i < ref.length; i++) {
							$(ref[i]).show();
							forms.showIf.enableFormElements($(ref[i]));
						}
					}
					else {
						for (var i = 0; i < ref.length; i++) {
							$(ref[i]).hide();
							forms.showIf.disableFormElements($(ref[i]));
						}
					}
				}

				// change functionality
				select.on('change', function () {
					// hide non-selected
					select.find('option[data-show-if]').each(function () {
						var opt = $(this);
						if (opt.is(':selected')) {
							check(opt, true);
						}
						else {
							check(opt, false);
						}
					});
				});

				// on load
				select.find('option[data-show-if]').each(function () {
					var opt = $(this);
					if (opt.is(':selected')) {
						check(opt, true);
					}
					else {
						check(opt, false);
					}
				});

				// done
				select.addClass('js-show-if-done');
			});
		}

	}

};

$(function () {
	forms.init();
	$(document).ajaxComplete(function () {
		forms.init();
	});
});